<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap21Slices"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 21: Slices" image-name="slice.jpg" image-alt="Slices"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a slice?</p></li>
<li><p>How to create a slice.</p></li>
<li><p>How to iterate over a slice</p></li>
<li><p>How to create multi-dimensional slices.</p></li>
<li><p>How to put an element at a specific index in a slice.</p></li>
<li><p>How to use append and copy.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Slice</p></li>
<li><p>Length</p></li>
<li><p>Capacity</p></li>
<li><p>Array</p></li>
<li><p>Copy</p></li>
<li><p>Pointer to</p></li>
<li><p>Variadic function</p></li>
</ul>
<div id="definition" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Definition <a href="#definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A slice is a <strong>growable collection</strong> of elements of the <strong>same type</strong>. It’s growable because you do not fix at compile time the size of your slice; you can add elements during the execution. When you add elements to the slice during the program execution, we say that the slice will grow.</p>
<p>The type of a slice is denoted by <span v-highlightjs><code class="go" v-pre style="display: inline">[]T</code></span> where T is the name of the type of elements inside the slice.</p>
<p>For instance: <span v-highlightjs><code class="go" v-pre style="display: inline">[]int</code></span> is a slice of integers.</p>
<p>Note that we do not specify the size of a slice as we do for arrays (see chapter <a href="#chap:Arrays" data-reference-type="ref" data-reference="chap:Arrays">[chap:Arrays]</a>). Inside the square brackets, we don’t specify any length. The zero value of a slice is nil.</p>
<div id="creation-of-a-new-slice" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Creation of a new slice <a href="#creation-of-a-new-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<pre v-highlightjs><code class="go" v-pre >// slices/creation/main.go
package main

func main() {
    s := make([]int, 3)
    s[0] = 12
    s[2] = 3
}</code></pre>
<p>Here we define the variable <span v-highlightjs><code class="go" v-pre style="display: inline">s</code></span> that is of type <span v-highlightjs><code class="go" v-pre style="display: inline">[]int</code></span> which is a slice of integers. Then we can fill the slice. We define the elements at indexes 0 and 2.</p>
<pre v-highlightjs><code class="go" v-pre >s2 = []int{10,12}</code></pre>
<p>We define <span v-highlightjs><code class="go" v-pre style="display: inline">s2</code></span>which is also a slice of integers, and we set its first two elements.</p>
<div id="slicing-an-array-a-pointer-to-an-array-or-a-slice" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Slicing an array, a pointer to an array, or a slice <a href="#slicing-an-array-a-pointer-to-an-array-or-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A slice is a piece of something. For instance, a slice of cheese is not the complete cheese but only a part of it. In Go, it’s the same you can slice :</p>
<ul>
<li><p>an array</p></li>
<li><p>a pointer to an array</p></li>
<li><p>a slice</p></li>
</ul>
<p>The result of this operation (called slicing) is a slice. To slice an element, four you can use the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >s := e[low:high]</code></pre>
<p>low and high will allow you to select the range of elements in <span v-highlightjs><code class="go" v-pre style="display: inline">e</code></span>.</p>
<div id="example-1" class="anchor"></div>
<h3 data-number="5.0.1"><span class="header-section-number">5.0.1</span> Example 1 <a href="#example-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// slices/slicing-array/main.go
package main

import &quot;fmt&quot;

func main() {
    customers := [4]string{&quot;John Doe&quot;, &quot;Helmuth Verein&quot;, &quot;Dany Beril&quot;, &quot;Oliver Lump&quot;}
    // slice the array
    customersSlice := customers[0:1]
    fmt.Println(customersSlice)
}</code></pre>
<p>This program will output :</p>
<pre v-highlightjs><code class="go" v-pre >[John Doe]</code></pre>
<p>We have an array of strings named <span v-highlightjs><code class="go" v-pre style="display: inline">customers</code></span>. This slice contains 4 elements.</p>
<p>We create a slice from this array. <span v-highlightjs><code class="go" v-pre style="display: inline">customers[0:1]</code></span> will create a slice containing the element of the array from index 0 to index 1-1=0. In other words, we take the first element.</p>
<div id="example-2" class="anchor"></div>
<h3 data-number="5.0.2"><span class="header-section-number">5.0.2</span> Example 2 <a href="#example-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >customersSlice2 := customers[2:4]
fmt.Println(customersSlice2)</code></pre>
<p>This program will output :</p>
<pre v-highlightjs><code class="go" v-pre >[Dany Beril Oliver Lump]</code></pre>
<p>We take the elements from indice <span class="math inline">2</span> to index 4-1=3</p>
<div id="remember" class="anchor"></div>
<h3 data-number="5.0.3"><span class="header-section-number">5.0.3</span> Remember <a href="#remember"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>When you write :</p>
<pre v-highlightjs><code class="go" v-pre >s := e[low:high]</code></pre>
<p>You have to subtract one from <span v-highlightjs><code class="go" v-pre style="display: inline">high</code></span> to get the highest element index in e.</p>
<p>Another way to remember it is that we take the elements from low to high <strong>excluded</strong>.</p>
<div id="do-slicing-copy-data" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Do slicing copy data? <a href="#do-slicing-copy-data"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>No! Here is an example :</p>
<pre v-highlightjs><code class="go" v-pre >// slices/slicing-copy/main.go
package main

import &quot;fmt&quot;

func main() {
    customers := [4]string{&quot;John Doe&quot;, &quot;Helmuth Verein&quot;, &quot;Dany Beril&quot;, &quot;Oliver Lump&quot;}
    customersSlice := customers[0:1]
    fmt.Println(customersSlice)
    // modify original array
    customers[0] = &quot;John Doe Modified&quot;
    fmt.Println(&quot;After modification of original array&quot;)
    fmt.Println(customersSlice)
}</code></pre>
<p>This program output :</p>
<pre v-highlightjs><code class="go" v-pre >[John Doe]
After modification of the original array
[John Doe Modified]</code></pre>
<p>When we create the slice <span v-highlightjs><code class="go" v-pre style="display: inline">customersSlice</code></span> the data is not copied, but <strong>a reference to the original data</strong> is taken. The value at index 0 in the slice will change.</p>
<div id="slicing-a-string" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Slicing a string <a href="#slicing-a-string"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You can also slice a string! The result of this operation is another string :</p>
<pre v-highlightjs><code class="go" v-pre >hotelName := &quot;Go Dev Hotel&quot;
s := hotelName[0:6]
fmt.Println(s)</code></pre>
<p>This program will output : <span v-highlightjs><code class="go" v-pre style="display: inline">Go Dev</code></span>. Strings in Go are <strong>immutable</strong>. Once created and saved in memory, you cannot modify the string. Let’s see an example:</p>
<pre v-highlightjs><code class="go" v-pre >// slices/slicing-string/main.go
package main

import &quot;fmt&quot;

func main() {
    hotelName := &quot;Go Dev Hotel&quot;
    s := hotelName[0:6]
    fmt.Println(s)
    hotelName = &quot;Java Dev Hotel&quot;
    fmt.Println(s)
}</code></pre>
<p>In this snippet, we first create a string (<span v-highlightjs><code class="go" v-pre style="display: inline">hotelName</code></span>) Then we slice it. The slice is named <span v-highlightjs><code class="go" v-pre style="display: inline">s</code></span>. Then we modify the value of <span v-highlightjs><code class="go" v-pre style="display: inline">hotelName</code></span>.</p>
<p>We might expect that <span v-highlightjs><code class="go" v-pre style="display: inline">s</code></span> will be modified, but it is not. The program will output :</p>
<pre v-highlightjs><code class="go" v-pre >Go Dev
Go Dev</code></pre>
<div id="length" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Length <a href="#length"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The length is the <strong>number of elements that are into the slice</strong>. The built-in function <span v-highlightjs><code class="go" v-pre style="display: inline">len</code></span> return the length of a slice :</p>
<pre v-highlightjs><code class="go" v-pre >vatRates := []float64{4.65, 4, 15, 20}
fmt.Printf(&quot;length of slice vatRates is %d&quot;, len(vatRates))</code></pre>
<div id="internal-memory-representation" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Internal memory representation <a href="#internal-memory-representation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Internally a slice is a struct that contains <strong>a pointer to an array</strong>.</p>
<ul>
<li><p>When we create a slice, Go will create an array. You will never have access to it. This array is <strong>internal</strong>.</p></li>
<li><p>When we slice an array, Go will take a pointer to that existing array.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/slice_internal_rep.png')" alt="Slice internal representation[fig:Slice-internal-representation]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Slice internal representation<span id="fig:Slice-internal-representation" label="fig:Slice-internal-representation">[fig:Slice-internal-representation]</span></figcaption>
</figure>
<p>The slice is the conjunction of three elements :</p>
<ol type="1">
<li><p>A pointer to an underlying array. The pointer points to the underlying array where the slice begins. It points to the <strong>first element of the slice</strong>.</p></li>
<li><p>The length of the slice (an <span v-highlightjs><code class="go" v-pre style="display: inline">uint</code></span>)</p></li>
<li><p>The capacity. (an <span v-highlightjs><code class="go" v-pre style="display: inline">uint</code></span>) (see next section)</p></li>
</ol>
<p>When we create a slice in our program, an array will be created. This array will contain the elements of the slice. Internally Go will store a pointer to the first element of this array (see figure <a href="#fig:Slice-internal-example" data-reference-type="ref" data-reference="fig:Slice-internal-example">2</a>).</p>
<figure>
<b-img :src="require('@/assets/images/slice_inernal_example.png')" alt="Slice internal example[fig:Slice-internal-example]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Slice internal example<span id="fig:Slice-internal-example" label="fig:Slice-internal-example">[fig:Slice-internal-example]</span></figcaption>
</figure>
<p>A slice can also be created by slicing an existing array. In this case, the slice’s capacity is not equal to its length.</p>
<figure>
<b-img :src="require('@/assets/images/slice_internal_when_slicing.png')" alt="Slice internal example when an array is sliced[fig:Slice-internal-example-when-array-sliced]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Slice internal example when an array is sliced<span id="fig:Slice-internal-example-when-array-sliced" label="fig:Slice-internal-example-when-array-sliced">[fig:Slice-internal-example-when-array-sliced]</span></figcaption>
</figure>
<div id="capacity" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Capacity <a href="#capacity"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The capacity is an unsigned integer. It represents the “the number of elements for which there is space allocated in the underlying array” Go Specification : https://golang.org/ref/spec#Length_and_capacity. To retrieve the capacity of a slice, you can use the builtin function <span v-highlightjs><code class="go" v-pre style="display: inline">cap(slice)</code></span>.</p>
<p>Let’s take an example to understand the concept of capacity :</p>
<pre v-highlightjs><code class="go" v-pre >names := [4]string{&quot;John&quot;, &quot;Bob&quot;, &quot;Claire&quot;, &quot;Nik&quot;}
mySlice := names[1:3]
fmt.Println(&quot;length:&quot;, len(mySlice))
fmt.Println(&quot;capacity:&quot;, cap(mySlice))</code></pre>
<p>This program will output :</p>
<pre v-highlightjs><code class="go" v-pre >length: 2
capacity: 3</code></pre>
<p>We created a slice named <span v-highlightjs><code class="go" v-pre style="display: inline">mySlice</code></span> by slicing the <span v-highlightjs><code class="go" v-pre style="display: inline">names</code></span> array. We take everything between the element at index one and the element at index 2 (3-1). <span v-highlightjs><code class="go" v-pre style="display: inline">mySlice</code></span> has a length of 2 (there are two elements inside). But the capacity is equal to 3. There is space allocated for three elements in the underlying array (names).</p>
<div id="length-and-capacity-relation" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Length and capacity relation <a href="#length-and-capacity-relation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p><strong>Capacity is always greater or equal to the length</strong>. It does not make sense to have a capacity that is inferior to the length. Imagine a slice with a length of 3 and a capacity of 2. We can store only two elements in the underlying array!</p>
<div id="slices-in-function-parameters" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Slices in function parameters <a href="#slices-in-function-parameters"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A function that takes a slice as parameter can change the underlying array. Why? Because a slice is <u>internally</u> a pointer to an underlying array. Here is an example :</p>
<pre v-highlightjs><code class="go" v-pre >// slices/as-fct-parameters/main.go
package main

import &quot;fmt&quot;

func main() {
    s := []int{1, 2, 3}
    multiply(s, 2)
    fmt.Println(s)
    //[2 4 6]
}

func multiply(slice []int, factor int) {
    for i := 0; i &lt; len(slice); i++ {
        slice[i] = slice[i] * factor
    }
}</code></pre>
<p>Here we define a slice called <span v-highlightjs><code class="go" v-pre style="display: inline">s</code></span> (composed of integers). We create a function <span v-highlightjs><code class="go" v-pre style="display: inline">multiply</code></span> that takes a slice of int and a <span v-highlightjs><code class="go" v-pre style="display: inline">factor</code></span> (int) as parameter. The objective of this function is to multiply each element of a slice by a <span v-highlightjs><code class="go" v-pre style="display: inline">factor</code></span>. When <span v-highlightjs><code class="go" v-pre style="display: inline">s</code></span> is created Go will internally create an array that will contain the values 1,2 and 3. It will also create a pointer to that array. When we pass <span v-highlightjs><code class="go" v-pre style="display: inline">s</code></span> to the function <span v-highlightjs><code class="go" v-pre style="display: inline">multiply</code></span>, it will modify the underlying array. <strong><u>Slices are pointers by nature</u></strong>.</p>
<div id="common-error" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Common error <a href="#common-error"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>When you pass a slice to a function, the function can modify the slice by changing the underlying array values</p></li>
<li><p>When you add elements to the slice inside the function and your slice reaches its maximum capacity, the runtime allocates a new underlying array.</p></li>
<li><p>That behavior is normal; however, at the end of the function execution, the elements added to the slice will not be present as you might expect.</p></li>
</ul>
<div id="example" class="anchor"></div>
<h3 data-number="12.1.1"><span class="header-section-number">12.1.1</span> Example: <a href="#example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li>Let’s take an example to demonstrate this:</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// slices/common-error-function/main.go
package main

import &quot;fmt&quot;

func main() {
    languages := []string{&quot;Java&quot;, &quot;PHP&quot;, &quot;C&quot;}
    fmt.Println(&quot;Capacity :&quot;, cap(languages))
    // Capacity : 3
    
    // call function
    addGo(languages)
    
    fmt.Println(&quot;Capacity :&quot;, cap(languages))
    // Capacity : 3
    fmt.Println(languages)
    // [Java PHP C]
    // what ! , where is Go ?????
}

func addGo(languages []string) {
    languages = append(languages, &quot;Go&quot;)
    fmt.Println(&quot;in function, capacity&quot;, cap(languages))
}</code></pre>
<div id="explanations" class="anchor"></div>
<h3 data-number="12.1.2"><span class="header-section-number">12.1.2</span> Explanations <a href="#explanations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li><p>We create a function addGo that will append an element to a slice of strings.</p></li>
<li><p>In the main function, we initialize a slice of strings named languages.</p></li>
<li><p>We initialize the slice with three elements. Its capacity and length are equal to 3.</p></li>
<li><p>We then call the function addGo. After the call, we print the slice, and no elements have been appended.</p></li>
</ul>
<p>What happens in the function?</p>
<ul>
<li><p>When the function is called, the runtime makes a copy of the slice.</p></li>
<li><p>A new underlying array is allocated inside the function because the capacity is exceeded.</p></li>
<li><p>The reference to the underlying array that the slice holds internally has been changed.</p></li>
<li><p>But, it has been changed only on the copy of the slice.</p></li>
<li><p>When the function returns, the copied slice is destroyed. The slice languages still reference the old underlying array.</p></li>
</ul>
<div id="how-to-avoid-that" class="anchor"></div>
<h3 data-number="12.1.3"><span class="header-section-number">12.1.3</span> How to avoid that <a href="#how-to-avoid-that"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li><p>addGo should accept a pointer to a slice of strings as input parameter.</p></li>
<li><p>In that condition the slice is not copied.</p></li>
<li><p>The reference to the underlying array is updated in the original slice.</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// slices/common-error-function-fix/main.go
package main

import &quot;fmt&quot;

func main() {
    languages := []string{&quot;Java&quot;, &quot;PHP&quot;, &quot;C&quot;}
    fmt.Println(&quot;Capacity :&quot;, cap(languages))
    // Capacity : 3

    // call function
    addGoFixed(&amp;languages)

    fmt.Println(&quot;Capacity :&quot;, cap(languages))
    // Capacity : 6
    fmt.Println(languages)
    // [Java PHP C Go]
}

func addGoFixed(languages *[]string) {
    *languages = append(*languages, &quot;Go&quot;)
}</code></pre>
<ul>
<li><span v-highlightjs><code class="go" v-pre style="display: inline">*</code></span> means “follow the address”, this is the dereferencing operator (if that’s not clear, take a look at the chapter dedicated to pointers)</li>
</ul>
<div id="make-built-in-function" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> make built-in function <a href="#make-built-in-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>With the make builtin, you can create a slice with a specified length and capacity. <span v-highlightjs><code class="go" v-pre style="display: inline">make</code></span> will return a new slice. You must provide the type of elements you want to put into the slice and its length and capacity. Note that the capacity is optional. If not provided, the capacity will be equal to the length.</p>
<pre v-highlightjs><code class="go" v-pre >test := make([]int,2,10)</code></pre>
<p>The slice <span v-highlightjs><code class="go" v-pre style="display: inline">test</code></span> will have a length of 2 and a capacity of 10. Be careful the capacity cannot be inferior to the length; otherwise, it will not compile!</p>
<div id="copy-builtin-function" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> copy builtin function <a href="#copy-builtin-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Copy is a handy function that allows you to copy all slice elements (called source) into another slice (called destination). The copy function takes two slices as parameters. The built-in function will copy all second slice elements into the first one. We call the first slice the destination and the second one the source.</p>
<p>The function returns an integer which is the number of elements successfully copied.</p>
<pre v-highlightjs><code class="go" v-pre >func copy(dst, src []Type) int</code></pre>
<p>Note that the number of elements copied will be the minimum between the source’s length and the length of the destination.</p>
<p>For instance, a slice has four elements, and the source slice has 2. Then the number of elements copied will be 2. If the destination has a length of 1, then the number of elements copied will be just one. The built-in copy does not increase the length of the destination slice. If you want to have the same slice, you have to provide a destination slice with the same length as the source slice.</p>
<figure>
<b-img :src="require('@/assets/images/slices_copy.png')" alt="Usage of copy"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Usage of copy</figcaption>
</figure>
<p>Here is a usage example of the copy builtin :</p>
<pre v-highlightjs><code class="go" v-pre >// slices/copy-builtin/main.go
package main

import &quot;fmt&quot;

func main() {
    // destination &gt; source
    a := []int{10, 20, 30, 40}
    b := []int{1, 1, 1, 1, 1}
    copy(b, a)
    fmt.Printf(&quot;a: %v - b : %v\n&quot;, a, b)
    // Output : a: [10 20 30 40] - b : [10 20 30 40 1]

    // source &gt; destination
    a = []int{10, 20, 30, 40}
    b = []int{1, 1}
    copy(b, a)
    fmt.Printf(&quot;a: %v - b : %v\n&quot;, a, b)
    // Output : a: [10 20 30 40] - b : [10 20]

    // source = destination
    a = []int{10, 20, 30, 40}
    b = make([]int, 4)
    copy(b, a)
    fmt.Printf(&quot;a: %v - b : %v\n&quot;, a, b)
    // Output : a: [10 20 30 40] - b : [10 20 30 40]
}</code></pre>
<p>I had difficulties remembering that the first argument of copy is the destination and the other one is the source.</p>
<p>Here is a mnemotechnic tip to remember: the parameters’ name is in alphabetical order. D then S!</p>
<div id="append-builtin-function" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> append builtin function <a href="#append-builtin-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The append function will add element(s) at the end of a slice.</p>
<figure>
<b-img :src="require('@/assets/images/append.png')" alt="Append builtin"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Append builtin</figcaption>
</figure>
<p>Append accepts as first parameter a slice and then one or several elements to append to it. We say that append is a variadic function.</p>
<p>A function is said to be <strong>variadic</strong> when it has a <strong>variable number of parameters</strong>. The function definition does not fix the number of parameters. In the signature of the function, you can detect this by looking for the three dots <strong>"...</strong>".</p>
<pre v-highlightjs><code class="go" v-pre >func append(slice []Type, elems ...Type) []Type</code></pre>
<p>Here the function takes a slice as a parameter but also elems that are of type <span v-highlightjs><code class="go" v-pre style="display: inline">Type</code></span> the dots means that we can provide <strong>several values for the second parameter elems</strong>. Append returns the slice modified. Let’s take an example usage :</p>
<pre v-highlightjs><code class="go" v-pre >// slices/append/main.go
package main

import &quot;fmt&quot;

func main() {
    a := []int{10, 20, 30, 40}
    a = append(a, 50)
    fmt.Println(a)
    // [10 20 30 40 50]

}</code></pre>
<p>Here we first create a slice <span v-highlightjs><code class="go" v-pre style="display: inline">a</code></span> and then we append the integer <span v-highlightjs><code class="go" v-pre style="display: inline">50</code></span> to it by using <span v-highlightjs><code class="go" v-pre style="display: inline">append</code></span><strong>.</strong>Note that the <span v-highlightjs><code class="go" v-pre style="display: inline">append</code></span> function will <strong>return the modified version of</strong><span v-highlightjs><code class="go" v-pre style="display: inline">a</code></span><strong>.</strong> Therefore we must reassign the modified version to the variable <span v-highlightjs><code class="go" v-pre style="display: inline">a</code></span><strong>.</strong></p>
<p>At the end of the script, we print <span v-highlightjs><code class="go" v-pre style="display: inline">a</code></span> and we get the slice a with new elements inside. Append has resized our original slice automatically. If the underlying array’s capacity is insufficient, the append function will allocate a new one.</p>
<div id="how-slices-grow" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> How slices grow <a href="#how-slices-grow"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>With the builtin <span v-highlightjs><code class="go" v-pre style="display: inline">append</code></span> we can add more data at the end of a slice. Internally when we append a value to a slice, we set a value in the underlying array. The underlying array has a specific size. If there is no space left on the array, Go will create a new array with sufficient space. Go will then copy all the elements from the previous array to the new array. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >s := []uint{10, 20, 30, 40}
fmt.Printf(&quot;Length : %d - Capacity : %d\n&quot;, len(s), cap(s))
s = append(s, 50)
fmt.Printf(&quot;Length : %d - Capacity : %d\n&quot;, len(s), cap(s))
s = append(s, 60)
fmt.Printf(&quot;Length : %d - Capacity : %d\n&quot;, len(s), cap(s))
s = append(s, 70)
fmt.Printf(&quot;Length : %d - Capacity : %d\n&quot;, len(s), cap(s))
s = append(s, 80)
fmt.Printf(&quot;Length : %d - Capacity : %d\n&quot;, len(s), cap(s))
s = append(s, 90)
fmt.Printf(&quot;Length : %d - Capacity : %d\n&quot;, len(s), cap(s))</code></pre>
<p>In this program, we create a slice s with four initial elements then we append elements to it. We log the slice’s length and its capacity after each step. The program outputs :</p>
<pre v-highlightjs><code class="go" v-pre >Length : 4 - Capacity : 4
Length : 5 - Capacity : 8
Length : 6 - Capacity : 8
Length : 7 - Capacity : 8
Length : 8 - Capacity : 8
Length : 9 - Capacity : 16</code></pre>
<ul>
<li><p>At first, we have four elements and an underlying array of 4 elements.</p></li>
<li><p>When we add one element to the slice, the capacity increase to 8.</p>
<ul>
<li><p>It means that a new underlying array of 8 elements has been created.</p></li>
<li><p>The elements present in the old array are all <strong>copied</strong> to the new array.</p></li>
</ul></li>
<li><p>When we add <span v-highlightjs><code class="go" v-pre style="display: inline">90</code></span> to the slice <span v-highlightjs><code class="go" v-pre style="display: inline">length == capacity</code></span>. The underlying array is full.</p>
<ul>
<li><p>A new array of <span v-highlightjs><code class="go" v-pre style="display: inline">16</code></span> elements is created.</p></li>
<li><p>The elements present in the old array are all copied to the new one.</p></li>
</ul></li>
</ul>
<p>On figure <a href="#fig:Slice-growing" data-reference-type="ref" data-reference="fig:Slice-growing">4</a> you can see how the slice is growing.</p>
<figure>
<b-img :src="require('@/assets/images/slice_growing.png')" alt="Slice growing[fig:Slice-growing]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Slice growing<span id="fig:Slice-growing" label="fig:Slice-growing">[fig:Slice-growing]</span></figcaption>
</figure>
<div id="performance-impact" class="anchor"></div>
<h4 data-number="16.0.0.1"><span class="header-section-number">16.0.0.1</span> Performance impact <a href="#performance-impact"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>When the underlying array is full, a new array is created, and your data is copied into the new array. This operation can impact the performance of your program. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >func grow1() {
    s := []uint{10, 20, 30, 40}
    s = append(s, 50)
    s = append(s, 60)
    s = append(s, 70)
    s = append(s, 80)
    s = append(s, 90)
}

func grow2() {
    s := make([]uint, 9)
    s = append(s, 10, 20, 30, 40)
    s = append(s, 50)
    s = append(s, 60)
    s = append(s, 70)
    s = append(s, 80)
    s = append(s, 90)
}</code></pre>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">grow2</code></span> is relatively faster than <span v-highlightjs><code class="go" v-pre style="display: inline">grow1</code></span>. In <span v-highlightjs><code class="go" v-pre style="display: inline">grow2</code></span> we create a slice using the built-in <span v-highlightjs><code class="go" v-pre style="display: inline">make</code></span>. We explicitly say that we want a length of 9 (and thus a capacity of 9). The runtime will create the underlying array once. In <span v-highlightjs><code class="go" v-pre style="display: inline">grow1</code></span> Go will create two underlying arrays (and copy all element from one array to another one). Here is a benchmark (see chapter <a href="#chap:Benchmark" data-reference-type="ref" data-reference="chap:Benchmark">[chap:Benchmark]</a>) that I run on my computer Mac book Pro - 2,2 GHz Quad-Core Intel Core i7 - 16 GB 1600 MHz DDR3. <span v-highlightjs><code class="go" v-pre style="display: inline">grow1</code></span> : average: 94.8 nanoseconds per operation</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">grow2</code></span> : average: 58.7 nanoseconds per operation</p>
<div id="slice-elements-index" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Slice elements index <a href="#slice-elements-index"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Elements in a slice are indexed. The indexing starts at <strong>0</strong>. In the computer science world, we start counting at 0. It is often a source of errors for beginners. Please keep that in mind.</p>
<figure>
<b-img :src="require('@/assets/images/slice_indexing.png')" alt="Indexes of a slice"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Indexes of a slice</figcaption>
</figure>
<div id="access-an-element-by-its-index" class="anchor"></div>
<h1 data-number="18"><span class="header-section-number">18</span> Access an element by its index <a href="#access-an-element-by-its-index"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>So if you want to access the element at index three from a slice a, you can use the following code :</p>
<pre v-highlightjs><code class="go" v-pre >elementAtIndex3 := a[3]</code></pre>
<p>You have to be sure that the element at index exists. Otherwise, you might face a <strong>runtime panic</strong>. For instance, the following program will compile (the compiler will not check the index existence). Here is an example :</p>
<pre v-highlightjs><code class="go" v-pre >// slices/access-element-index/main.go
package main

import &quot;fmt&quot;

func main() {
    a := []int{10, 20, 30, 40}
    fmt.Println(a[8])
}</code></pre>
<p>This program will compile. We want to access the element at index 8. But this element does not exists. Which makes the program panic :</p>
<pre v-highlightjs><code class="go" v-pre >$ go run main.go
panic: runtime error: index out of range</code></pre>
<p>If you try to access an element of an array out of index, the program will not compile. Arrays have a length known at compile-time; slices have a length that can grow during program execution.</p>
<div id="iterate-over-elements-of-a-slice" class="anchor"></div>
<h1 data-number="19"><span class="header-section-number">19</span> Iterate over elements of a slice <a href="#iterate-over-elements-of-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To iterate over the elements of a slice, you can use a for loop :</p>
<pre v-highlightjs><code class="go" v-pre >names := []string{&quot;John&quot;, &quot;Bob&quot;, &quot;Claire&quot;, &quot;Nik&quot;}
for i, name := range names {
    fmt.Println(&quot;Element at index&quot;, i, &quot;=&quot;, name)
}</code></pre>
<p>This program will output :</p>
<pre v-highlightjs><code class="go" v-pre >Element at index 0 = John
Element at index 1 = Bob
Element at index 2 = Claire
Element at index 3 = Nik</code></pre>
<p>At each iteration :</p>
<ul>
<li><p>The value of <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> is equal to the index iteration value.</p></li>
<li><p>The value of <span v-highlightjs><code class="go" v-pre style="display: inline">name</code></span> is equal to the value of the slice element at index <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span>.</p></li>
</ul>
<div id="attention-danger-for-loops-that-modify-a-slice" class="anchor"></div>
<h2 data-number="19.1"><span class="header-section-number">19.1</span> Attention danger: for loops that modify a slice <a href="#attention-danger-for-loops-that-modify-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Imagine that you need to modify each element of a slice. A for loop with a range expression can do the job. But there is a beginner mistake to avoid.</p>
<p>Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// Warning : there is a trap!
names := []string{&quot;John&quot;, &quot;Bob&quot;, &quot;Claire&quot;, &quot;Nik&quot;}
for _, name := range names {
    name = strings.ToUpper(name)
}
fmt.Println(names)</code></pre>
<p>This program will output :</p>
<pre v-highlightjs><code class="go" v-pre >[John Bob Claire Nik]</code></pre>
<p>The original slice has not been modified! The range expression will copy the value of each element to the local variable <span v-highlightjs><code class="go" v-pre style="display: inline">name</code></span>. <span v-highlightjs><code class="go" v-pre style="display: inline">name = strings.ToUpper(name)</code></span> will modify only the local variable. The original slice names are left untouched!</p>
<pre v-highlightjs><code class="go" v-pre >for i := range names {
    names[i] = strings.ToUpper(names[i])
}
fmt.Println(names)</code></pre>
<p>The program will output :</p>
<pre v-highlightjs><code class="go" v-pre >[JOHN BOB CLAIRE NIK]</code></pre>
<div id="merge-two-slices" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="20"><span class="header-section-number">20</span> Merge two slices <a href="#merge-two-slices"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The following code snippet will merge <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >b := append(b, c...)</code></pre>
<p>Note that <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span> have to be of the same type.</p>
<pre v-highlightjs><code class="go" v-pre >test := append([]int{10,20}, []int{30,40,50}...)</code></pre>
<p>The variable test will be equal to the next slice :</p>
<pre v-highlightjs><code class="go" v-pre >[10,20,30,40,50]</code></pre>
<div id="find-an-element-in-a-slice" class="anchor"></div>
<h1 data-number="21"><span class="header-section-number">21</span> Find an element in a slice <a href="#find-an-element-in-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>There is no built-in function for that.</p>
<p>You have to iterate over each slice element and check if the element is equal to the one you are looking for.</p>
<pre v-highlightjs><code class="go" v-pre >names := []string{&quot;John&quot;, &quot;Bob&quot;, &quot;Claire&quot;, &quot;Nik&quot;}
for i, name := range names {
    if name == &quot;Claire&quot; {
        fmt.Println(&quot;Claire found at index&quot;, i)
    }
}
// Output : Claire found at index 2</code></pre>
<div id="remove-an-element-at-index-algorithm-from-httpsgithub.comgolanggowikislicetricks" class="anchor"></div>
<h1 data-number="22"><span class="header-section-number">22</span> Remove an element at index Algorithm from : https://github.com/golang/go/wiki/SliceTricks <a href="#remove-an-element-at-index-algorithm-from-httpsgithub.comgolanggowikislicetricks"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s say we have a slice of length 10 :</p>
<pre v-highlightjs><code class="go" v-pre >[1,2,3,4,5,6,7,8,9,10]</code></pre>
<p>We want to eliminate the element at index 8 (which is 9). The technique is to create two slices. The first slice will contain the elements from index 0 to index 7. The second slice will contain just one element: the one at index 9.</p>
<p>We then merge the two slices.</p>
<figure>
<b-img :src="require('@/assets/images/slice_delete_one_element.png')" alt="Delete the element at index 8[fig:Delete-the-element-at-index]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Delete the element at index 8<span id="fig:Delete-the-element-at-index" label="fig:Delete-the-element-at-index">[fig:Delete-the-element-at-index]</span></figcaption>
</figure>
<p>Here is the code to do this with Go :</p>
<pre v-highlightjs><code class="go" v-pre >a := []int{1, 2, 3, 4, 5, 6, 7, 8, 9, 10}
a = append(a[:8], a[9:]...)</code></pre>
<p>First, we create a slice called a.</p>
<p>Then use the built-in append to concatenate two slices :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">mySlice[:8]</code></span> : which is <span v-highlightjs><code class="go" v-pre style="display: inline">mySlice</code></span> reduced to elements at indexes <strong>0 to 7.</strong>(remember that the index after the “:” is always excluded). This is B in the figure <a href="#fig:Delete-the-element-at-index" data-reference-type="ref" data-reference="fig:Delete-the-element-at-index">5</a></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">mySlice[9:]</code></span> : which contains all elements of <span v-highlightjs><code class="go" v-pre style="display: inline">mySlice</code></span> from index nine included. This is C in the figure <a href="#fig:Delete-the-element-at-index" data-reference-type="ref" data-reference="fig:Delete-the-element-at-index">5</a></p></li>
</ul>
<p>Then we concatenate the two slices to obtain our new slice that does not contain the element at index 8!</p>
<p>Here is a generalization of the algorithm. If you have a slice s and you want to delete the element at index i <span v-highlightjs><code class="go" v-pre style="display: inline">i&lt;len(s)</code></span> then you can use the following :</p>
<pre v-highlightjs><code class="go" v-pre >a = append(a[:i], a[i+1:]...)</code></pre>
<div id="put-an-element-at-index-algorithm-from-httpsgithub.comgolanggowikislicetricks" class="anchor"></div>
<h1 data-number="23"><span class="header-section-number">23</span> Put an element at index Algorithm from : https://github.com/golang/go/wiki/SliceTricks <a href="#put-an-element-at-index-algorithm-from-httpsgithub.comgolanggowikislicetricks"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We have a slice; we want to add an element to it but <strong>at a specific index</strong>. The idea is to use the copy builtin :</p>
<pre v-highlightjs><code class="go" v-pre >// objective put &quot;C&quot; at index 2
// index:            0    1    2    3    4
letters := []string{&quot;A&quot;, &quot;B&quot;, &quot;D&quot;, &quot;E&quot;, &quot;F&quot;}

// 1) add an element to the end of the slice
letters = append(letters, &quot;&quot;)

// 2) copy letters[i:] to letters[i+1:]
copy(letters[3:], letters[2:])

// 3) set &quot;C&quot; at index 2
letters[2] = &quot;C&quot;</code></pre>
<figure>
<b-img :src="require('@/assets/images/slice_put_one_elem_to_slice.png')" alt="Put an element at a specific index"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Put an element at a specific index</figcaption>
</figure>
<p>Let’s take another example : we have a slice of strings that contains names. The length is 4 :</p>
<pre v-highlightjs><code class="go" v-pre >b := []string{&quot;john&quot;, &quot;jeanne&quot;, &quot;jean&quot;, &quot;josh&quot;}</code></pre>
<p>We want to add the name <strong>joe</strong> at index 1. Our target slice is :</p>
<pre v-highlightjs><code class="go" v-pre >[&quot;john&quot;,&quot;joe&quot;,&quot;jeanne&quot;,&quot;jean&quot;,&quot;josh&quot;]</code></pre>
<p>We will first add an element to our slice to increase its length (from 4 to 5). We use the append builtin that will put it at the end of the slice :</p>
<pre v-highlightjs><code class="go" v-pre >b = append(b, &quot;&quot;)</code></pre>
<p>Now we have the next slice :</p>
<pre v-highlightjs><code class="go" v-pre >[&quot;john&quot;, &quot;jeanne&quot;, &quot;jean&quot;, &quot;josh&quot;,&quot;&quot;]</code></pre>
<p>The length is 5. The idea then is to transform this slice into :</p>
<pre v-highlightjs><code class="go" v-pre >[&quot;john&quot;, &quot;&quot;, &quot;joe&quot;,&quot;jeanne&quot;, &quot;jean&quot;, &quot;josh&quot;]</code></pre>
<p>and then we set the element at index 1 with the desired value.</p>
<p>Let’s summarize we want to perform the following transformation : <span v-highlightjs><code class="go" v-pre style="display: inline">["john", "jeanne", "jean", "josh",""]</code></span> into <span v-highlightjs><code class="go" v-pre style="display: inline">["john", "X", "jeanne", "jean", "josh"]</code></span> To do that, we can use <strong>copy</strong>:</p>
<ul>
<li><p>The <strong>destination</strong> slice is <span v-highlightjs><code class="go" v-pre style="display: inline">["jean", "josh",""]</code></span> which is <span v-highlightjs><code class="go" v-pre style="display: inline">b[2:]</code></span></p></li>
<li><p>and our <strong>source</strong> slice will be <span v-highlightjs><code class="go" v-pre style="display: inline">["jeanne", "jean", "josh",""]</code></span> which is <span v-highlightjs><code class="go" v-pre style="display: inline">b[1:]</code></span></p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >copy(b[2:], b[1:])</code></pre>
<p>We end up with the following slice :</p>
<pre v-highlightjs><code class="go" v-pre >[&quot;john&quot;, &quot;jeanne&quot;, &quot;jeanne&quot;, &quot;jean&quot;,&quot;josh&quot;]</code></pre>
<p>Note that we have now two <strong>jeanne</strong> element. That’s because we performed a copy and the value of the element at index 1 is not affected, but the element at index two has been replaced by the element that was originally at index 1.</p>
<p>We now just have to give to the element at index one the new value <span v-highlightjs><code class="go" v-pre style="display: inline">"joe"</code></span>.</p>
<pre v-highlightjs><code class="go" v-pre >b[1] = &quot;joe&quot;</code></pre>
<div id="generalization" class="anchor"></div>
<h2 data-number="23.1"><span class="header-section-number">23.1</span> Generalization <a href="#generalization"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When <span v-highlightjs><code class="go" v-pre style="display: inline">s</code></span> is a slice of integers :</p>
<pre v-highlightjs><code class="go" v-pre >s = append(s, 0)
copy(s[i+1:], s[i:])
s[i] = x</code></pre>
<div id="remove-all-elements-of-a-slice" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="24"><span class="header-section-number">24</span> Remove all elements of a slice <a href="#remove-all-elements-of-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="a0" class="anchor"></div>
<h2 data-number="24.1"><span class="header-section-number">24.1</span> a[:0] <a href="#a0"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can remove all elements of a given slice by using the slicing operator. The trick is to slice our slice. We will take 0 elements from it :</p>
<pre v-highlightjs><code class="go" v-pre >a := []int{1, 2, 3, 4, 5, 6, 7, 8, 9, 10}
a = a[:0]
fmt.Println(a)
// []
fmt.Println(len(a))
// 0
fmt.Println(cap(a))
// 10</code></pre>
<p>With the slicing operator <span v-highlightjs><code class="go" v-pre style="display: inline">[:0]</code></span> we have removed all elements of the slice.</p>
<p>Therefore the length is now equal to 0. But the capacity (which is the size of the underlying array) is still <strong>10</strong>.</p>
<p>This means that the underlying array still exists and has a length of 10.</p>
<div id="set-the-slice-to-nil" class="anchor"></div>
<h2 data-number="24.2"><span class="header-section-number">24.2</span> Set the slice to nil <a href="#set-the-slice-to-nil"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can set the value of your slice to nil. By doing so, we are removing the data in memory.</p>
<pre v-highlightjs><code class="go" v-pre >b := []int{1, 2, 3, 4, 5, 6, 7, 8, 9, 10}
b = nil
fmt.Println(b)
// []
fmt.Println(len(b))
// 0
fmt.Println(cap(b))
// 0</code></pre>
<p>Note that the capacity is now equal to zero, which means that the memory holding the underlying array has been freed and will be garbage collected by the internal go script.</p>
<div id="prepend-an-element-to-a-slice" class="anchor"></div>
<h1 data-number="25"><span class="header-section-number">25</span> Prepend an element to a slice <a href="#prepend-an-element-to-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We can use the append built-in to append an entry to a slice.</p>
<p>But to prepend, it seems that we have no built-in at our disposal. But you can use the built-in append to prepend :</p>
<pre v-highlightjs><code class="go" v-pre >b := []int{2, 3, 4}
b = append([]int{1}, b...)</code></pre>
<p>We are building a new slice with the element that we want to prepend, and then we add all the elements of the original slice.</p>
<div id="multidimensional-slices" class="anchor"></div>
<h1 data-number="26"><span class="header-section-number">26</span> Multidimensional slices <a href="#multidimensional-slices"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Multi-dimensional slices allow you to create a complex data structure that is easy to manipulate.</p>
<div id="creation-of-two-dimensional-slices" class="anchor"></div>
<h2 data-number="26.1"><span class="header-section-number">26.1</span> Creation of two-dimensional slices <a href="#creation-of-two-dimensional-slices"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Sometimes your date does not fit entirely on one dimension. Imagine that you work for a supermarket. You want to study the baskets of the clients. You may want to analyze the price distributions inside baskets. You have a detailed price list for each basket. For instance :</p>
<table>
<caption>Price list example</caption>
<thead>
<tr class="header">
<th style="text-align: center;">basket #</th>
<th style="text-align: center;">prices</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">1</td>
<td style="text-align: center;"><span class="math inline">1.23;1;12.40;3;20</span></td>
</tr>
<tr class="even">
<td style="text-align: center;">2</td>
<td style="text-align: center;"><span class="math inline">40;2.45;23</span></td>
</tr>
<tr class="odd">
<td style="text-align: center;">...</td>
<td style="text-align: center;">.…</td>
</tr>
<tr class="even">
<td style="text-align: center;">1000</td>
<td style="text-align: center;"><span class="math inline">5</span></td>
</tr>
</tbody>
</table>
<p>We can use a two dimensional slice to store it. The type will be<span v-highlightjs><code class="go" v-pre style="display: inline">[][]float64</code></span>. It’s a slice that will contain slices of floats values :</p>
<pre v-highlightjs><code class="go" v-pre >// slices/multidimensional/main.go
package main

import &quot;fmt&quot;

func main() {
    innerSlice1 := []float64{2.30, 4.01, 6.99, 8}
    innerSlice2 := []float64{1, 10.20, 30, 1.34, 23}
    my2DSlice := [][]float64{innerSlice1, innerSlice2}
    fmt.Println(my2DSlice)
}</code></pre>
<p>We first define two slices of float64 (<span v-highlightjs><code class="go" v-pre style="display: inline">innerSlice1</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">innerSlice2</code></span>). And then we create our two-dimensional slice (named <span v-highlightjs><code class="go" v-pre style="display: inline">my2DSlice</code></span>) that will contains <span v-highlightjs><code class="go" v-pre style="display: inline">innerSlice1</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">innerSlice2</code></span>.</p>
<p>The following snippet is a lighter version of the previous script :</p>
<pre v-highlightjs><code class="go" v-pre >// slices/2d-dimension/main.go
package main

import &quot;fmt&quot;

func main() {
    my2DSlice := [][]float64{[]float64{2.30, 4.01, 6.99, 8}, []float64{1, 10.20, 30, 1.34, 23}}
    fmt.Println(my2DSlice)
}</code></pre>
<p>We directly put the two inner slices into the main one (without using two additional variables).</p>
<div id="creation-of-two-dimensional-slices-dynamically" class="anchor"></div>
<h2 data-number="26.2"><span class="header-section-number">26.2</span> Creation of two-dimensional slices dynamically <a href="#creation-of-two-dimensional-slices-dynamically"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can use the built-in <span v-highlightjs><code class="go" v-pre style="display: inline">append</code></span> to construct your 2-D slice dynamically:</p>
<pre v-highlightjs><code class="go" v-pre >myOther2DSlice := [][]int{}
for i := 0; i &lt; 10; i++ {
    myOther2DSlice = append(myOther2DSlice, []int{i})
}
fmt.Println(myOther2DSlice)
//[[0] [1] [2] [3] [4] [5] [6] [7] [8] [9]]</code></pre>
<p>We first define our 2-D slice <span v-highlightjs><code class="go" v-pre style="display: inline">myOther2DSlice</code></span>. The inner slices are slices of integers.</p>
<p>Then we create a for-loop. We will iterate from 0 to 9. Each time we will append a new slice to <span v-highlightjs><code class="go" v-pre style="display: inline">myOther2DSlice</code></span>. This slice will contain just one number: the current value of <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span>.</p>
<p>At the end of the execution, we will get a slice composed of 10 inner slices. Each of those inner slices contains one single integer.</p>
<div id="more-than-two-dimensions" class="anchor"></div>
<h2 data-number="26.3"><span class="header-section-number">26.3</span> More than two dimensions? <a href="#more-than-two-dimensions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Handling dimensions that are higher than two is not complicated.</p>
<p>A 3-D slice will be defined using <span v-highlightjs><code class="go" v-pre style="display: inline">[][][]T</code></span> where <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span> is the type of elements inside the slices.</p>
<p>Here is an example :</p>
<pre v-highlightjs><code class="go" v-pre >store12 := [][]float64{[]float64{2.30, 4.01, 6.99, 8}, []float64{1, 10.20, 30, 1.34, 23}}
store34 := [][]float64{[]float64{1, 1.25}, []float64{2.45}, []float64{1.99, 2.45}}
my3DSlice := [][][]float64{store12,store34}
fmt.Println(my3DSlice)
//[[[2.3 4.01 6.99 8] [1 10.2 30 1.34 23]] [[1 1.25] [2.45] [1.99 2.45]]]</code></pre>
<p>To create slices that have a larger dimension, just add an additional <span v-highlightjs><code class="go" v-pre style="display: inline">[]</code></span> to the type definition :</p>
<pre v-highlightjs><code class="go" v-pre >// 4-D
fourDimensions := [][][][]int

// 5-D
fiveDimensions := [][][][][]int</code></pre>
<div id="dimensions-increase-while-readability-decreases." class="anchor"></div>
<h3 data-number="26.3.1"><span class="header-section-number">26.3.1</span> Dimensions increase while readability decreases. <a href="#dimensions-increase-while-readability-decreases."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Note that when you increase your slices’ dimension, you also increase the time needed by the others to understand your algorithm.</p>
<p>The human brain is not used to spaces with more than three dimensions.…</p>
<p>In my opinion, we should avoid 3+ dimensions.</p>
<div id="use-another-data-structure" class="anchor"></div>
<h3 data-number="26.3.2"><span class="header-section-number">26.3.2</span> Use another data structure? <a href="#use-another-data-structure"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>In the previous example, we can maybe use another data structure... Take a look at the map chapter and then come back to this example to see how you can improve the code’s readability.</p>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="27"><span class="header-section-number">27</span> Test Yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<h2 class="unnumbered" id="questions">Questions</h2>
<ol type="1">
<li><p>Fill in the blanks : “A slice is a ______ to an underlying _____”.</p></li>
<li><p>How to find the index of an element in a slice?</p></li>
<li><p>Internally, what is behind a slice?</p></li>
<li><p>What is the capacity of <span v-highlightjs><code class="go" v-pre style="display: inline">names</code></span> in the following example : <span v-highlightjs><code class="go" v-pre style="display: inline">names := []string{"John", "Bob", "Claire", "Nik"}</code></span>. Explain why.</p></li>
<li><p>What is the capacity of <span v-highlightjs><code class="go" v-pre style="display: inline">s2</code></span> in the following example : <span v-highlightjs><code class="go" v-pre style="display: inline">s2 := names[0:2]</code></span>. Explain why.</p></li>
<li><p>What happens internally when you append an element to a slice with a capacity equals to its length?</p></li>
</ol>
<h2 class="unnumbered" id="answers">Answers</h2>
<ol type="1">
<li><p>Fill in the blanks : “A slice is a ______ to an underlying _____”.</p>
<ol type="1">
<li>A slice is a <strong>pointer</strong> to an underlying <strong>array.</strong></li>
</ol></li>
<li><p>How to find the index of an element in a slice?</p>
<ol type="1">
<li>you can use a for loop with a range statement : <span v-highlightjs><code class="go" v-pre style="display: inline">for index, value := range s</code></span></li>
</ol></li>
<li><p>Internally, what is behind a slice?</p>
<ol type="1">
<li><p>A struct composed of three fields :</p>
<ol type="1">
<li><p>A pointer to an underlying array</p></li>
<li><p>The length (a uint)</p></li>
<li><p>The capacity (a uint)</p></li>
</ol></li>
</ol></li>
<li><p>What is the capacity of <span v-highlightjs><code class="go" v-pre style="display: inline">names</code></span> in the following example : <span v-highlightjs><code class="go" v-pre style="display: inline">names := []string{"John", "Bob", "Claire", "Nik"}</code></span>. Explain why.</p>
<ol type="1">
<li>Capacity is equal to 4. When the slice names is created, Go will allocate an underlying array of length and capacity equal to 4.</li>
</ol></li>
<li><p>What is the capacity of <span v-highlightjs><code class="go" v-pre style="display: inline">s2</code></span> in the following example : <span v-highlightjs><code class="go" v-pre style="display: inline">s2 := names[0:2]</code></span>. Explain why.</p>
<ol type="1">
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">s2</code></span> is created by slicing an existing slice (<span v-highlightjs><code class="go" v-pre style="display: inline">names</code></span>).</p></li>
<li><p>We take slice from index <strong>0</strong> to index <strong>1</strong> (2-1).</p></li>
<li><p>This slice’s length will be 2 (there are two elements in the slice).</p></li>
<li><p>The capacity is equal to “the number of elements for which there is space allocated in the underlying array”.</p></li>
<li><p>There are two more elements allocated in the underlying array. The capacity is equal to <strong>4</strong>.</p></li>
</ol></li>
<li><p>What happens internally when you append an element to a slice with a capacity equals to its length?</p>
<ol type="1">
<li><p>When the slice has its capacity equals to its length, it means that there is no space left on the underlying array</p></li>
<li><p>When you append an element to the slice, the runtime will create a new array (its length will be twice the previous one).</p></li>
<li><p>All data from the first array will be copied to the second array.</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="28"><span class="header-section-number">28</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>A slice is a growable collection of elements of the same type</p></li>
<li><p>A slice is a pointer to an underlying array</p></li>
<li><p><strong>Length</strong>: number of elements of the slice</p></li>
<li><p><strong>Capacity</strong>: number of elements for which there is space allocated in the underlying array</p></li>
<li><p>To create an <strong>empty slice</strong> with a given length and capacity, you can use the builtin make</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">s := make([]int,0,10)</code></span> create a slice of integers, with a length of <strong>0</strong> and a capacity of <strong>10</strong></p></li>
<li><p>To add elements to a slice, use the <strong>append</strong> builtin</p>
<pre v-highlightjs><code class="go" v-pre >s = append(s,2020,2021)</code></pre></li>
<li><p>To find an element in a slice, you will need to iterate through the slice</p>
<ul>
<li>If you need to do so, maybe you can use a map instead.</li>
</ul></li>
</ul>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap21Slices"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap20Arrays'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Arrays</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap22Maps'}">
									<p><u><small>Next</small></u></p>
									<p><small>Maps</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Slices - Practical Go Lessons"
const description = "A slice is a growable collection of elements of the same type. It’s growable because you do not fix at compile time the size of your slice; you can add elements during the execution. When you add elements to the slice during the program execution, we say that the slice will grow."

export default {
  name: "Chap21Slices",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
